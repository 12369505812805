import axios from "axios";
const baseURL = 'https://api.admin-present.gomaplus.tech/api'

export const imageBase = 'https://api.admin-present.gomaplus.tech'

const client = axios.create({ baseURL });
export const request = async ({ ...options }) => {

  return client(options).then((res) => res);
};
 