import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { imageBase } from "../api/request";

const areEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.product) === JSON.stringify(nextProps.product) &&
    prevProps.dir === nextProps.dir && prevProps.lng === nextProps.lng 
  );
};

const ProductCard = ({ product, dir , lng }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      key={product.id}
      sx={{
        display: "flex",
        my: 2,
        alignItems: dir === "row" ? "center" : "flex-start",
        gap: "20px",
        flexDirection: dir === "row" ? "row-reverse" : dir,
        justifyContent: dir === "row" ? "space-between" : "flex-start",
      }}
    >
      <Box
        sx={{
          width: dir === "column" ? "100%" : "6rem",
          height: dir === "column" ? "auto" : "4rem",
          position: "relative",
          flexShrink: 0,
          paddingTop: dir === "column" ? "66.7%" : "0",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <img
            alt={product.name}
            src={imageBase + product.image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          {i18n.language === "ar" ? product.name_ar : product.name}
        </Typography>
       
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            color: "primary.main",
          }}
        >
          {i18n.language === "ar"
            ? product.description_ar
            : product.description}
        </Typography>
        <Typography
        sx={{
          "& .price": {
            color: "common.white",
            backgroundColor: "primary.main",
            fontSize: "14px",
            p: "2px 6px",
            borderRadius: "20px",
          },
          "& .calories": {
            color: "primary.main",
            fontSize: "14px",
          },
        }}
      >
        <span className="price">
          {product.price} {t("currency")}
        </span>{" "}
        <span className="calories">
          {product.calories} {t("calories")}
        </span>
      </Typography>
      </Box>
    </Box>
  );
};

export default memo(ProductCard, areEqual);
