import { Box, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Background from "../assets/images/background.JPG";
import BackgroundSplash from "../assets/images/bg.svg";
import poweredGoma from '../assets/images/poweredGoma.png'
import {
  Dns,
  FormatListBulleted,
  PanToolAltOutlined,
} from "@mui/icons-material";
import useGetCategroirs from "../api/useGetCategroirs";
import { useTranslation } from "react-i18next";
import { imageBase } from "../api/request";
import i18n from "../i18n";
import { AnimatePresence, motion } from "framer-motion";
import Language from "./Language";
import {
  Link,
  Element,
  scrollSpy,
  animateScroll as scroll,
} from "react-scroll";
import CategoryCard from "./CategoryCard";
import logo from '../assets/images/logo.png'

const MotionTypography = motion(Typography);
const MotionBox = motion(Box);

const Home = () => {
  const [dir, setDir] = useState("row");
  const { t } = useTranslation();
  const catgeories = useGetCategroirs();
  const [activeIndex, setActiveIndex] = useState(0);

  const containerRef = useRef([]);

  const [activeCategory, setActiveCategory] = useState();
  const [showLoading, setShowLoading] = useState(true); // State to control loading visibility

  const scrollToTopButton = useRef(null);
  const holeScreen = useRef(null);

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      delay: 400,
    });
  };

  useEffect(() => {
    if (containerRef.current[activeIndex]) {
      containerRef.current[activeIndex].scrollIntoView({
        behavior: "auto",
        inline: "center",
      });
    }
  }, [activeIndex]);

  const handleSetActive = (category, index) => {
    setActiveCategory(category);
    setActiveIndex(index);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 4000); // Minimum 4 seconds delay

    return () => clearTimeout(timer);
  }, []);

  if (showLoading || catgeories.isLoading) {
    return (
      <AnimatePresence>
        <MotionBox
          sx={{
            width: "100%",
            height: "100svh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",
           backgroundColor:"#222222",
            backgroundRepeat: "no-repeat", 
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          style={{
            transformOrigin: "center",
          }}
          initial={{
            scale: 0,
            opacity: 0,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          exit={{
            scale: 0,
            opacity: 0,
          }}
        >
          <MotionTypography
            sx={{
              fontSize: "25px",
              fontWeight: "700",
              textAlign: "center",
              my: 3,
              color: "primary.light",
            }}
            animate={{
              scale: [1, 1.05, 1],
              transition: {
                duration: 1.0,
                repeat: Infinity,
              },
            }}
          >
          
          <img src={logo} style={{width:'200px'}}/> 
          </MotionTypography>
          <Typography
            sx={{
              fontSize: "18px",
              textAlign: "center",
              color: "primary.dark",
              textTransform: "capitalize",
              position: "absolute",
              // left : '50%',
              bottom: "20px",
            }}
          >
          <a href="https://social.gomaksa.com/omar" target="_blank">
           <img src={poweredGoma} width='100px'/>
           </a>
          </Typography>
        </MotionBox>
      </AnimatePresence>
    );
  }

  return (
    <Box ref={holeScreen}>
      <Container maxWidth="sm">
        <Box
          sx={{
            height: "400px",
            width: "100%",
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <IconButton
          ref={scrollToTopButton}
          onClick={scrollToTop}
          color="primary"
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "1000",
            backgroundColor: "primary.main",
            color: "common.white",
            transition: "0.2s",
            animation: "bounce 2s infinite",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            "@keyframes bounce": {
              "0%, 20%, 50%, 80%, 100%": {
                transform: "translateY(0)",
              },
              "40%": {
                transform: "translateY(-10px)",
              },
              "60%": {
                transform: "translateY(-5px)",
              },
            },
            "&:hover": {
              transform: "scale(1.05)",
              backgroundColor: "primary.dark",
            },
          }}
          size="large"
        >
          <PanToolAltOutlined fontSize="large" />
        </IconButton>
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          mt: "-75px",
          mx: "auto",
          backgroundColor: "#000",
          position: "relative",
        }}
      >
        <img src={logo} style={{width:'100px'}}/>
      </Box>
        {/* contact info */}
        <Box
          sx={{
            my: 2,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              textTransform: "capitalize",
              fontWeight: "500",
              fontSize: "18px",
              mb: 1,
            }}
          >
            {t("menu_name")}
          </Typography>
          <Language />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            maxWidth: "100%",
            py: 2,
            overflowX: "auto",
            mb: 6,
            position: "sticky",
            top: "0px",
            backgroundColor: "rgba(247, 243, 232, 0.87)",
            zIndex: "100",
            "&::-webkit-scrollbar": {
              height: "3px",
            },
          }}
        >
          {catgeories.data.data.data.map((cat, index) => {
            return (
              <Link
                key={index}
                activeClass="active"
                to={cat.name}
                spy={true}
                smooth={true}
                offset={-200}
                duration={50}
                onSetActive={() => {
                  handleSetActive(cat.name, index);
                }}
              >
                <Box
                  ref={(el) => (containerRef.current[index] = el)}
                  sx={{
                    width: "100px",
                    borderRadius: "50%",
                    transition: "0.4s",
                    color: activeCategory === cat.name ? "primary.main" : "",
                    transform: `scale(${
                      activeCategory === cat.name ? "1.05" : "0.8"
                    })`,
                  }}
                >
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      minWidth: "100px",
                      minHeight: "100px",
                      borderRadius: "50%",
                      backgroundImage: `url(${imageBase + cat.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      mb: 1,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {i18n.language === "ar" ? cat.name_ar : cat.name}
                  </Typography>
                </Box>
              </Link>
            );
          })}
        </Box>

        <Box
          sx={{
            mb: 4,
          }}
        >
          <Box
            sx={{
              borderRadius: "30px",
              border: "1px solid black",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                p: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: dir === "row" ? "common.black" : "transparent",
                color: dir === "row" ? "common.white" : "grey.500",
                borderRadius: "50%",
                transition: "0.3s",
              }}
              onClick={() => {
                setDir("row");
              }}
            >
              <FormatListBulleted fontSize="medium" />
            </Box>
            <Box
              sx={{
                p: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  dir === "column" ? "common.black" : "transparent",
                color: dir === "column" ? "common.white" : "grey.500",
                borderRadius: "50%",
                transition: "0.3s",
              }}
              onClick={() => {
                setDir("column");
              }}
            >
              <Dns fontSize="medium" />
            </Box>
          </Box>
        </Box>

        <Box
          
        >
          {catgeories.data.data.data.map((cat) => {
            return (
              <Element key={cat.id} name={cat.name}>
                <CategoryCard lng={i18n.language} cat={cat} dir={dir} />
              </Element>
            );
          })}
        </Box>
        <Box sx={{
          pt : '50px'
        }}> <Typography >{t("Note")}  {t("Note1")} </Typography></Box>  
        <Box sx={{
          pb : '100px'
        }}> <Typography >{t("Note")}  {t("Note2")} </Typography></Box>  
        
      </Container>
    </Box>
  );
};

export default Home;
