import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import Home from "./components/Home";

import "./i18n";
import i18n from './i18n'
import "./App.css"

const theme = createTheme({
  direction : i18n.language === 'ar' ? 'rtl' : 'ltr',
  palette: {
    background: {
      default: "#f7f3e8",
      paper: "#f7f3e8",
    },
    primary: {
      main: "rgb(119, 97, 81)",
    },
  },
  components : {
    MuiButton : {
      styleOverrides:  {
        root : {
          gap : '8px'
        }
      }
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home />
    </ThemeProvider>
  );
};

export default App;
