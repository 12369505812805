import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";

const areEqual = (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.cat) === JSON.stringify(nextProps.cat) &&
      prevProps.dir === nextProps.dir && prevProps.lng === nextProps.lng 
    );
  };

const CategoryCard = ({ cat, dir , lng }) => {
  const { i18n } = useTranslation();
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "600",
          mb: 1,
          position: "relative",
        }}
      >
        {i18n.language === "ar" ? cat.name_ar : cat.name}
      </Typography>
      {cat.product.map((product) => (
        <ProductCard lng={lng} product={product} key={product.id} dir={dir} />
      ))}
    </Box>
  );
};

export default memo(CategoryCard , areEqual);
