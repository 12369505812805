import {
  ArrowDropDown,
  CheckOutlined,
  LanguageOutlined,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Language = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={handleClickOpen}
        startIcon={<ArrowDropDown />}
        endIcon={<LanguageOutlined />}
        dir="rtl"
      >
        {i18n.language === "ar" ? t("arabic") : t("english")}
      </Button>
      <Dialog
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-end",
            backdropFilter: "blur(3px)",
          },
          "& .MuiPaper-root": {
            margin: "0",
            borderRadius: "10px 10px 0 0",
            width : {xs : '100%' , sm : '600px'},
            pb : '100px'
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography>{t("language_button")}</Typography>
            <Button onClick={handleClose}>{t("cancel")}</Button>
          </Box>
          <Divider
            sx={{
              borderColor: "common.white",
              my: 2,
            }}
          />
          <Box
            onClick={() => {
              i18n.changeLanguage("ar");
              document.dir = "rtl";
              handleClose();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <Typography>{t("arabic")}</Typography>
            {i18n.language === "ar" && <CheckOutlined color="primary" />}
          </Box>
          <Divider
            sx={{
              borderColor: "common.white",
              my: 2,
            }}
          />
          <Box
            onClick={() => {
              i18n.changeLanguage("en");
              document.dir = "ltr";
              handleClose();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <Typography>{t("english")}</Typography>
            {i18n.language === "en" && <CheckOutlined color="primary" />}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Language;
